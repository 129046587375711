import React from "react";
import PropTypes from "prop-types";

const WarningTextBox = ({ text }) => {
  return (
    <div className="warning-text-box">
      <span className="warning-text-box__icon" />
      <span className="warning-text-box__text">{text}</span>
    </div>
  );
};

WarningTextBox.propTypes = {
  text: PropTypes.string.isRequired,
};

export default WarningTextBox;