import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getPostcodeListing = async (params) => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { lookupID: "POSTCODE", sort: "seq,asc" };
		const res = await api.get.general.list(params);
		const content = res.map(({ postcode, value, ...o }) => ({ label: postcode, value: postcode, ...o }));
		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getPostcodeListing;
