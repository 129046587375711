import React, { memo, useMemo, useState, useCallback, Fragment } from "react";
import { Menu, MenuItem, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

import classNames from "common/class-names";
import { formatCurrency } from "common/format-currency-pattern";
import AppButton from "components/app-button";
import AppCloseIcon from "components/icons/app-close-icon";
import logo from "assets/images/travel_logo.png";


const AppCard = (props) => {
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [downloadMenuAnchor, setDownloadMenuAnchor] = useState(null);
	const className = useMemo(() => {
		return classNames({
			"main-content__radio": true,
			"main-content__radio--active": props.checked,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.checked]);

	const onHandleChange = () => {
		props.onChange(props.data.planId);
	};

	const onhandleCloseMenu = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onHandleShowAMenu = useCallback(() => {
		const target = document.querySelector(".main__box");
		setAnchorEl(target);
	}, []);

	const onhandleCloseDownloadMenu = useCallback(() => {
		setDownloadMenuAnchor(null);
	}, [setDownloadMenuAnchor]);

	//prettier-ignore
	const onHandleShowDownloadMenu = useCallback((event) => {
		setDownloadMenuAnchor(event.target);
	},[setDownloadMenuAnchor]);

	return (
		<div className="app-card">
			<div className="main-content">
				<div className="main-content__box">
					<button className={className} type="button" disabled={props.disabled} onClick={onHandleChange} />
				</div>

				<div className="main-content__container">
					<div className="header">
						<div className="header__company">
							<div className="header__image">
								<img src={logo} alt="image" />
							</div>

							<div className="header__content header__content--title">
								<p className="header__title">{props.data?.plan.planName}</p>
								<p className="header__title header__title--content">{props.data?.provider?.providerName}</p>
							</div>
						</div>

						<div className="header__company--right">
							<div className="header__content header__content--title">
								<p className="header__title">Premium from</p>
								<p className="header__title header__title--content">{`RM${formatCurrency(props.premiumAmount)}`}</p>
							</div>
						</div>
					</div>
					
					<div className="main-content__divider"></div>

					<div className="header__content">
						<div className="header__wrapper">
							<div className="header__text">Benefit Coverage</div>
						</div>
						<div className="header__wrapper">
							<div className="header__title">{`Up to RM${formatCurrency(props.coverageAmount)}`}</div>
						</div>
					</div>

					<div className="body">
						<div className="body__content">
							<div className="body__content-row">
								<div className="body__content-item">
									{props?.benefit && props.benefit
										.map((o, i) => {
											return (
													<div className="body__wrapper">
														<img src={logo} alt="image" className="body__content-icon" />
														<p className="body__text">{o.benefitName}</p>
														<p className="body__text">Up to RM{formatCurrency(o.coverageAmount)}</p>
													</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>

					{/* <div>
					 	<div className="body__content-item" key={i}>
					 		<img src={logo} alt="pic" className="body__content-icon" />
					 		<span className="body__text">{o.benefitName}</span>
						</div>
					</div> */}

					<div className="main-content__divider"></div>

					<div className="footer">
						{/* prettier-ignore */}
						<AppButton type="button" label="View More" onClick={onHandleShowAMenu} />
					</div>
				</div>

				{/* prettier-ignore */}
				<Menu classes={{ root: "app-detail-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "top", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
					<div className="detail-menu">
						<div className="detail-menu__container" id={props.name}>
							<div className="header">
								<div className="header__company">
										<img src={props.data?.benefit?.icon} className="header__image" alt="profile" />
									<p className="header__title">{props.data?.planId}</p>
								</div>

								<button type="button" className="detail-menu__remove" onClick={onhandleCloseMenu}>
									<AppCloseIcon color="#666666" />
								</button>
							</div>

							<div className="detail-menu__divider" />

							<div className="body">								
								<div className="body__list">
									{props.benefit?.map((o, i) => {
										return (
											<div className="body__item" key={i}>
												<img src={props.data?.benefit?.icon} className="body__image" alt="profile" />
												<span className="body__text">{o}</span>
											</div>
										)
									})}
								</div>
									
							</div>

							<div className="detail-menu__divider" />

							<div className="footer">
								<div>
									<div className="footer__content">
										<div className="footer__upper-content" >
											<div className="footer__wrapper">
												<div className="footer__text">Coverage Limit</div>

												<div className="footer__text">{`RM${formatCurrency(props.data?.subLimit)}`}</div>
											</div>
										</div>
									</div>
								</div>
								
								<div>
									<div className="footer__premium-amount">
										<div className="footer__text">Basic Premium</div>

										<div className="footer__sub-title">{`RM${formatCurrency(props.data?.subLimit)}`}</div>
									</div>
								</div>
							</div>
						</div>

						<div className="detail-menu__button-container">
							<AppButton type="button" label="Download" onClick={onHandleShowDownloadMenu}/>
						</div>

						<Menu classes={{ root: "download-menu" }} anchorEl={downloadMenuAnchor} open={!!downloadMenuAnchor} onClose={onhandleCloseDownloadMenu}  anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} transformOrigin={{vertical: 'top' ,horizontal: 'center',}}>
							<MenuItem>
								<a className="download-menu__text" href={props.data?.providerDetails?.pdsLink} target="_blank" rel="noreferrer">PDS</a>
							</MenuItem>

							<MenuItem>
								<a className="download-menu__text" href={props.data?.providerDetails?.policyWordingLink} target="_blank" rel="noreferrer">Policy Wording</a>
							</MenuItem>

							<MenuItem>
								<a className="download-menu__text" href={props.data?.providerDetails?.compulsoryExcessLink} target="_blank" rel="noreferrer">Compulsory Excess</a>
							</MenuItem>
						</Menu>
					</div>
				</Menu>
			</div>

			<FormHelperText className="main-content__error">{errorMessage}</FormHelperText>
		</div>
	);
};

AppCard.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		planId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		plan: PropTypes.shape({
			id: PropTypes.string.isRequired,
			providerId: PropTypes.string.isRequired,
			planName: PropTypes.string,
			planCode: PropTypes.string,
			description: PropTypes.string,
			planStatus: PropTypes.string,
			provider: PropTypes.string
		}),
		providerId: PropTypes.string,
		provider: PropTypes.shape({
			id: PropTypes.string.isRequired,
			providerName: PropTypes.string,
			providerCode: PropTypes.string,
			contactPerson: PropTypes.string,
			contactEmail: PropTypes.string,
			contactPhone: PropTypes.string,
			providerStatus: PropTypes.string
		}),
		providerAgeGroupId: PropTypes.string,
		ageGroup: {
            id: 1,
            ageGroupName: PropTypes.string,
            minAge: PropTypes.string,
            maxAge: PropTypes.string,
            ageGroupType: PropTypes.string,
            ageGroupStatus: PropTypes.string
        },
		subLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	}),
	benefit: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			benefitName: PropTypes.string,
			benefitCode: PropTypes.string,
			benefitCategory: PropTypes.string,
			description: PropTypes.string,
			icon: PropTypes.string,
			seq: PropTypes.string,
			benefitStatus: PropTypes.string,
		})
	),
	coverageAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	premiumAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	checked: PropTypes.bool,
	touched: PropTypes.bool,
	error: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	className: PropTypes.string,
};

export default memo(AppCard);
