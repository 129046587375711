import COMMON from "common";
import services, {services_2} from "services";
import encodeQueryParams from "common/encode-query-params";

const api = {
	get: {
		general: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.LIST + encodeQueryParams(params), { header: { token: false } }),
			states: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.STATES + encodeQueryParams(params)),
			cities: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.CITIES + encodeQueryParams(params)),
			postcode: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.POSTCODE + encodeQueryParams(params)),
		},
		travel: {
			paymentStatus: (payload) => services.get(COMMON.ENDPOINT_PATH.INSURER.PAYMENT_STATUS + encodeQueryParams(payload), { cancelId: COMMON.ENDPOINT_PATH.INSURER.PAYMENT_STATUS }),
		},
	},
	post: {
		travel: {
			quotation: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.QUOTATION, payload),
			updateQuotation: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.UPDATE_QUOTATION, payload),
			regenerateQuotation: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.REGENERATE_QUOTATION, payload),
			recalculateQuotation: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.RECALCULATE_QUOTATION, payload),
			payment: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.PAYMENT_CHECKOUT, payload),
			confirm: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.CONFIRM_QUOTATION, payload),
			postQuot: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.POST_QUOT , payload),
			postQuotId: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.POST_QUOT_ID, payload),
			travelGroup: (payload) => services_2.post(COMMON.ENDPOINT_PATH.INSURER.TRAVEL_GROUP , payload),
			travelIn: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.TRAVEL_IN , payload),
		},
	},
	patch: {},
	put: {},
};

export default api;
