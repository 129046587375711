import React, { memo, useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "common/class-names";

const FileUpload = ({
  id,
  label,
  required,
  disabled,
  multiple,
  maxFiles,
  onFilesChange,
  touched,
  error: propError,
  sampleFiles,
  files,
  accept,
  maxFileSize, // in bytes
  onError,
  templateFile ,
}) => {
  const fileInputRef = useRef(null);
  const [localError, setLocalError] = useState("");
  const [fileTimestamps, setFileTimestamps] = useState(new Map());

  const validateFileSize = useCallback((file) => {
    if (maxFileSize && file.size > maxFileSize) {
      return false;
    }
    return true;
  }, [maxFileSize]);

  const validateFileType = useCallback((file) => {
    if (!accept || accept === '*') return true;

    const acceptedTypes = accept.split(',').map(type => type.trim().toLowerCase());
    const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
    const fileType = file.type.toLowerCase();

    return acceptedTypes.some(type => {
      if (type.startsWith('.')) {
        return fileExtension === type;
      } else if (type.includes('/*')) {
        const [category] = type.split('/');
        return fileType.startsWith(category);
      } else {
        return fileType === type;
      }
    });
  }, [accept]);

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString();
  };

  const getOriginalFileName = (fileName) => {
    const parts = fileName.split('_');
    const timestamp = parts.pop().split('.')[0];
    if (timestamp && !isNaN(timestamp)) {
      return parts.join('_') + '.' + fileName.split('.').pop();
    }
    return fileName;
  };

  const handleFileUpload = useCallback((event) => {
    const newFiles = Array.from(event.target.files);
    setLocalError("");

    // Validate file types and sizes
    const invalidTypeFiles = newFiles.filter(file => !validateFileType(file));
    const invalidSizeFiles = newFiles.filter(file => !validateFileSize(file));

    if (invalidTypeFiles.length > 0) {
      const error = `Invalid file type(s): ${invalidTypeFiles.map(f => f.name).join(', ')}`;
      setLocalError(error);
      onError?.(error);
      return;
    }

    if (invalidSizeFiles.length > 0) {
      const error = `File(s) exceed maximum size: ${invalidSizeFiles.map(f => f.name).join(', ')}`;
      setLocalError(error);
      onError?.(error);
      return;
    }

    // Check for duplicates
    const duplicateFiles = newFiles.filter(newFile => 
      files.some(existingFile => 
        getOriginalFileName(existingFile.name) === newFile.name && 
        existingFile.size === newFile.size
      )
    );

    if (duplicateFiles.length > 0) {
      const error = `File(s) already uploaded: ${duplicateFiles.map(f => f.name).join(', ')}`;
      setLocalError(error);
      onError?.(error);
      return;
    }

    // Process new files
    const updatedFiles = [...files];
    const currentTime = Date.now();
    const newTimestamps = new Map(fileTimestamps);

    newFiles.forEach(newFile => {
      const timestamp = Date.now();
      const fileNameParts = newFile.name.split('.');
      const extension = fileNameParts.pop();
      const baseName = fileNameParts.join('.');
      const uniqueName = `${baseName}_${timestamp}.${extension}`;

      const uniqueFile = new File([newFile], uniqueName, {
        type: newFile.type,
        lastModified: newFile.lastModified
      });

      if (updatedFiles.length < maxFiles) {
        updatedFiles.push(uniqueFile);
        newTimestamps.set(uniqueFile.name, currentTime);
      }
    });

    if (updatedFiles.length > maxFiles) {
      const error = `Maximum number of files (${maxFiles}) exceeded`;
      setLocalError(error);
      onError?.(error);
      return;
    }

    setFileTimestamps(newTimestamps);
    onFilesChange(updatedFiles);
  }, [files, onFilesChange, maxFiles, validateFileType, validateFileSize, onError, fileTimestamps]);

  const removeFile = useCallback((index) => {
    const fileToRemove = files[index];
    const updatedFiles = files.filter((_, i) => i !== index);
    const newTimestamps = new Map(fileTimestamps);
    newTimestamps.delete(fileToRemove.name);

    setFileTimestamps(newTimestamps);
    onFilesChange(updatedFiles);
    setLocalError("");
  }, [files, onFilesChange, fileTimestamps]);

  const triggerFileInput = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const chooseFilesButtonClassName = useCallback(() => {
    return classNames({
      "file-upload__choose-button": true,
      "file-upload__choose-button--disabled": disabled,
    });
  }, [disabled]);

  const removeButtonClassName = useCallback(() => {
    return classNames({
      "file-upload__remove-button": true,
      "file-upload__remove-button--disabled": disabled,
    });
  }, [disabled]);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const getAcceptedFileTypesText = () => {
    if (!accept || accept === '*') return 'All files';
    return accept.split(',').map(type => type.trim()).join(', ');
  };

  return (
    <div className="file-upload">
      <div className="file-upload__header">
        {label && (
          <p className="file-upload__label">
            {label}
            {required && <span className="file-upload__required">*</span>}
          </p>
        )}
        <button
          type="button"
          onClick={triggerFileInput}
          disabled={disabled}
          className={chooseFilesButtonClassName()}
        >
          Choose Files
        </button>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        id={id || "fileUpload"}
        onChange={handleFileUpload}
        multiple={multiple}
        disabled={disabled}
        accept={accept}
        className="file-upload__input"
      />

      {sampleFiles && sampleFiles.length > 0 && (
        <div className="file-upload__sample-files">
          {templateFile && (
            <a 
              href={templateFile}
              download
              className="file-upload__template-link"
            >
              Click to Download Template File
            </a>
          )}
          <h4 className="file-upload__sample-files-title">Accepted file types:</h4>
          <ul className="file-upload__sample-files-item">{getAcceptedFileTypesText()}</ul>
          <h4 className="file-upload__sample-files-title">Sample Supporting Documents:</h4>
          <ul className="file-upload__sample-files-list">
            {sampleFiles.map((file, index) => (
              <li key={index} className="file-upload__sample-files-item">
                {file}
              </li>
            ))}
          </ul>
          <h4 className="file-upload__sample-files-title">Maximum file size:</h4>
          <ul className="file-upload__sample-files-item">{formatFileSize(maxFileSize)}</ul>
        </div>
      )}

      {files.length > 0 && (
        <div className="file-upload__file-list">
          <h3 className="file-upload__file-list-title">
            Uploaded Documents: {files.length}/{maxFiles}
          </h3>
          <ul className="file-upload__file-items">
            {files.map((file, index) => (
              <li key={`${file.name}-${index}`} className="file-upload__file-item">
                <div className="file-upload__file-info">
                  <div className="file-upload__file-main">
                    <span className="file-upload__file-name">
                      {getOriginalFileName(file.name)}
                    </span>
                    <span className="file-upload__file-name">
                      ({formatFileSize(file.size)})
                    </span>
                  </div>
                  <div className="file-upload__status-line">
                    <span className="file-upload__file-name">
                      (Uploaded at {formatTimestamp(fileTimestamps.get(file.name))})
                    </span>
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      disabled={disabled}
                      className={removeButtonClassName()}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {(touched && propError) && (
        <p className="file-upload__error">{propError}</p>
      )}

      {localError && (
        <p className="file-upload__error">{localError}</p>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  maxFiles: PropTypes.number,
  onFilesChange: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  error: PropTypes.string,
  sampleFiles: PropTypes.arrayOf(PropTypes.string),
  files: PropTypes.array,
  accept: PropTypes.string,
  maxFileSize: PropTypes.number,
  onError: PropTypes.func,
};

FileUpload.defaultProps = {
  multiple: true,
  disabled: false,
  required: false,
  maxFiles: 5,
  sampleFiles: [],
  files: [],
  accept: '*',
  maxFileSize: 5 * 1024 * 1024, // 5MB default
};

export default memo(FileUpload);