import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "common/class-names";

const AppCardInfo = (props) => {
	
	const className = useCallback((obj) => {
		return classNames({
			"list__text": true,
			"list__text--bold": obj?.bold,
		});
	}, []);

	return (
		<div className="app-card-info">
			<div className="card-info">
				<p className="card-info__title">{props.data?.title}</p>

				{props.data?.info?.map((o) => (
					<ul className="list" key={o.label}>
						{o?.divider == true && <p className="list__border"></p>}
						<li className="list__item">
							<p className={className({bold: o?.bold})}>{o.label}</p>
							<p className="list__text list__text--value">{o.value}</p>
						</li>
					</ul>
				))}
			</div>
		</div>
	);
};


AppCardInfo.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string.isRequired,
		info: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string.isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			})
		),
	}),
};

export default memo(AppCardInfo);
