import React, { useMemo, useCallback, useRef, useState, Fragment } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, FieldArray, FormikProvider, setNestedObjectValues } from "formik";

import COMMON from "common";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { CALENDAR_FORMAT } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { getDateOfBirthAge } from "common/get-date-of-birth-age";
import formatPassportString from "common/format-passport-string";
import { getDateOfBirthNRIC, validateNRIC } from "common/nric-helpers";
import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppCardInfo from "components/app-card-info";
import AppButtonInput from "components/app-button-input";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppMobileInput from "components/app-mobile-input";
import AppCloseIcon from "components/icons/app-close-icon";
import AppMaskingInput from "components/app-masking-input";
import AppCalendarInput from "components/app-calendar-input";
import AppCard from "components/pages/page-quotation/app-card";
import AppChevronIcon from "components/icons/app-chevron-icon";
import AppMultiSelectInput from "components/app-multi-select-input";
import AppCardContainer from "components/pages/page-quotation/app-card-container";
import AppRegenerateModal from "components/pages/page-quotation/app-regenerate-modal";
import AppAccordionContainer from "components/pages/page-quotation/app-accordion-container";
import api from "services/api";
import getOccupationListing from "services/get-occupation-listing";
import getRelationshipListing from "services/get-relationship-listing";
import getMaritalStatusListing from "services/get-maritalStatus-listing";
import getIdentificationListing from "services/get-identification-listing";
import { setQuotation, setQuotationList } from "store/slices/travel";
import addIcon from "assets/images/add-green-icon.svg";
import refreshIcon from "assets/images/refresh-icon.svg";
import editIcon from "assets/images/pages/page-quotation/edit-icon.svg";
import clockIcon from "assets/images/pages/page-quotation/clock-icon.webp";
import emptyIcon from "assets/images/pages/page-quotation/empty-box-icon.webp";
import logo from "assets/images/travel_logo.png";


const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const PageQuotation = () => {
	const searchInputRef = useRef();
	const timerCounter = useRef();
	const regenerateModalRef = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const travel = useSelector((state) => state.travel);
	const tripDetails = useMemo(() => travel?.travelDetails, [travel?.travelDetails]);
	const quotationInfo = useMemo(() => travel?.quotationList, [travel?.quotationList]);
	const schema = useMemo(() => yup.string().required(ERRORS.REQUIRED), []);
	const [filterCriteria, setFilterCriteria] = useState({ providerName: "", sortAmount: ""});	
	const quotationCheckout = useMemo(() => [1]); //enquiryInfo?.plan?.filter((o) => { return  o.status !== COMMON.MESSAGE_TYPE.FAILED }));
	const [messageType, setMessageType] = useState("");

	//prettier-ignore
	const initialValues = useMemo(() => {

		let payload = {
			id: "",
			planId: "",
			plan: {
				id: "",
				providerId: "",
				planName: "",
				planCode: "",
				description: "",
				planStatus: "",
				travelType: "",
				stampDuty: "",
				provider: ""
			},
			providerId: "",
			provider: {
				id: "",
				providerName: "",
				providerCode: "",
				contactPerson: "",
				contactEmail: "",
				contactPhone: "",
				providerStatus: ""
			},
			providerAgeGroupId: "",
			ageGroup: {
				id: "",
				ageGroupName: "",
				minAge: "",
				maxAge: "",
				ageGroupType: "",
				ageGroupStatus: "",
				benefits: [
					{
						id: "",
						benefitName: "",
						benefitCode: "",
						benefitCategory: "",
						description: "",
						icon: "",
						seq: "",
						benefitStatus: "",
						coverageAmount: ""
					}
				],
				premiumDetails: [
					{
						id: "",
						planId: "",
						providerId: "",
						providerAgeGroupId: "",
						providerAreaId: "",
						minDuration: "",
						maxDuration: "",
						premiumAmountIndividual:"",
						premiumAmountGroup: ""
					}
				]
			},
			coverageAmount: "",
			subLimit: ""
		}

		return payload;
	}, [])

	//prettier-ignore
	const formik = useFormik({
		initialValues,
		sortAmount: "",
		sortProviderName: "",
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const onHandleNavigateEnquiry = () => {
		navigate(pathnames.enquiry);
	};

	const onHandleNavigateApplicant = () => {
		navigate(pathnames.applicant);
	};

	const onHandleStartLoading = () => {
		clearInterval(timerCounter.current);

		timerCounter.current = setInterval(() => {
			setIsLoading(true);
			setLoading((prev) => {
				if (prev.length === 3) return "";
				return (prev += "•");
			});
		}, 1000);
	};

	//prettier-ignore
	const onHandleChecked = useCallback((planId) => {
		formik.setFieldValue("planId", planId);
		console.log("Plan Id is " + planId);
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback(() => {
		searchInputRef.current.onhandleCloseAdvanceSearch();
		setFilterCriteria(prev => ({
			...prev,
			providerName: formik.values.sortProviderName,
			sortAmount: formik.values.sortAmount
		}));
	}, [formik]);

	const onHandleResetSearch = () => {
		formik.setValues(initialValues);
		searchInputRef.current.onhandleCloseAdvanceSearch();
		setFilterCriteria(prev => ({    
			providerName: "",
			sortAmount: "",
		}))
	};

	const onHandleCloseSearch = () => {
		searchInputRef.current.onhandleCloseAdvanceSearch();
	};

	const onHandleRemoveField = (field, fieldValue) => {
		switch (field) {
			case "sort":
				formik.setFieldValue(field, "");
				break;
			case "insurerList":
				formik.setFieldValue(field, "");
				break;
			default:
				break;
		}
	};

	//prettier-ignore
	const onHandleCheckbox = useCallback((event) => {
		const name = event.currentTarget.name;
		const current = event.currentTarget.checked;

	}, [formik]);

	//prettier-ignore
	const onHandleValue = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value;

		formik.setFieldValue(`benefits[${name}]`, value);
	}, [formik]);

	const onHandleSwitch = useCallback(() => {
		const isAllBenefit = formik.values.isAllBenefit;
		const benefits = { ...formik.values.benefits };

		Object.keys(benefits).forEach((a) => {
			const benefitCode = a.replace("benefit_", "");
		});

		formik.setValues({ ...formik.values, benefits, isAllBenefit: !isAllBenefit });
	}, [formik]);

	const onHandleSubmit = async (values) => {
		let response = null;

		try{
			let payload = {
				planId: values.planId,
			};
			
			response = await api.post.travel.postQuotId(payload);

			dispatch(setQuotationList(response));
			dispatch(setQuotation(payload));
			onHandleNavigateApplicant();
		}
		catch (error) {
			if (!error?.response?.data?.errorCode) {
				if (error?.code === COMMON.ERRORS.NETWORK_ERROR) {
					setMessageType(COMMON.MESSAGE_TYPE.NETWORK);
				} else if (error?.code === COMMON.ERRORS.BE_BAD_RESPONSE) {
					setMessageType(COMMON.MESSAGE_TYPE.INVALID);
				}
			} else {
				serveRequestErrors(error);
			}
		}
	};

	const QuotationStatus = useCallback((obj) => {
		if (obj.isLoading) {
			return (
				<div className="card-insurer__body">
					<img className="card-insurer__clock-icon" src={clockIcon} alt="processing" />

					<p className="card-insurer__title">{`Processing${obj.loadingCount}`}</p>
					<p className="card-insurer__subtitle">Please wait for awhile</p>
				</div>
			);
		} else if (obj.noQuotationFound) {
			return (
				<div className="card-insurer__body">
					<img className="card-insurer__empty-box-icon" src={emptyIcon} alt="empty" />

					<p className="card-insurer__description">No Insurer Found</p>
					<p className="card-insurer__subtitle">Kindly re-enter your sum insured again or kindly contact our digital support consultant for assistance.</p>
					<a className="card-insurer__sub-description" href="mailto: easycover@support.com">
						easycover@support.com
					</a>
				</div>
			);
		} else {
			return null;
		}
	}, []);

	const getHighestPremiumAmountForPlan = (planId) => {
		const filteredData = quotationInfo.filter(item => item.planId === planId);
	  
		if (filteredData.length === 0) {
		  console.log(`No plans found for planId: ${planId}`);
		  return null;
		}
	  
		const highestPremiumAmount = Math.max(  
			...filteredData.flatMap(item =>   
			  Math.max(...item.ageGroup.premiumDetails.map(detail => {  
				// Calculate the premium amount based on the coverage type  
				const premiumAmount =   
				  coverageMap[tripDetails?.coverageType] === COMMON.COVER_TYPE.GROUP   
					? detail.premiumAmountGroup   
					: detail.premiumAmountIndividual;  
		  
				// If the calculated premium amount is 0, use the other amount  
				return premiumAmount === 0   
				  ? (coverageMap[tripDetails?.coverageType] === COMMON.COVER_TYPE.GROUP   
					  ? detail.premiumAmountIndividual   
					  : detail.premiumAmountGroup)  
				  : premiumAmount;  
			  }))  
			)  
		  );  
		  
		return highestPremiumAmount;
	};

	//prettier-ignore
	const QuotationCard = useCallback((obj) => {
		
		const BenefitData = (planId) => {
			let matchingBenefits;
				if(quotationInfo)
				{
					matchingBenefits = quotationInfo
						.filter(card => card.planId === planId)
					.map(card => card.ageGroup.benefits)
					.flat()
						.filter(
							(benefit, index, self) =>
								index === self.findIndex((b) => b.id === benefit.id));
				}

			return matchingBenefits;
		}
		
		const filterUniquePlanIds = (data) => {
			const seenPlanIds = new Set();
			return data.filter(object => {
				if (seenPlanIds.has(object.planId)) {
					return false;
				}
				seenPlanIds.add(object.planId);
				return true;
			});
		};

		const getHighestCoverageAmountForPlan = (planId) => {
			const filteredData = quotationInfo.filter(item => item.planId === planId);
		  
			if (filteredData.length === 0) {
			  return null;
			}
		  
			const highestCoverageAmount = Math.max(...filteredData.flatMap(item => 
				item.ageGroup.benefits.map( data => data.coverageAmount)));

			return highestCoverageAmount;
		};

		const filteredData = filterCriteria
			? filterUniquePlanIds(quotationInfo)
				.filter(item => {
					const matchesProviderName = filterCriteria.providerName
					? item.provider.providerName === filterCriteria.providerName
					: true;

					return matchesProviderName;
				})
				.sort((a, b) => {
					if (filterCriteria.sortAmount  === "Low to High") {
					return getHighestCoverageAmountForPlan(a.planId) - getHighestCoverageAmountForPlan(b.planId);
					}
					if (filterCriteria.sortAmount === "High to Low") {
					return getHighestCoverageAmountForPlan(b.planId) - getHighestCoverageAmountForPlan(a.planId);
					}
					return 0;
				})
			: filterUniquePlanIds(quotationInfo);

		return (
			<div className="card-insurer__appcard-container">
				{filteredData.map((object) => (
					<AppCard
						key={object.id}
						data={object}
						benefit={BenefitData(object.planId)}
						coverageAmount={getHighestCoverageAmountForPlan(object.planId)}
						premiumAmount={getHighestPremiumAmountForPlan(object.planId)}
						onChange={onHandleChecked}
						checked={object.planId === formik.values.planId}
					/>
				))}
			</div>
		);
	}, [formik]);

	const onHandleCheckEnterKey = (event) => {
		const code = event.keyCode ? event.keyCode : event.which;

		if (code === 13) {
			event.cancelBubble = true;
			event.returnValue = false;

			if (event.stopPropagation) {
				event.stopPropagation();
				event.preventDefault();
			}

			return false;
		}
	};

	const formatDateWithHyphens = (date) => {
		return date ? new Date(date).toLocaleDateString('en-GB').replace(/\//g, '-') : "-";
	};

	
	const coverageMap = {
		'INDIVIDUAL': "INDIVIDUAL",
		'INDIVIDUAL_V_SPOUSE': "INDIVIDUAL WITH SPOUSE",
		'FAMILY': "FAMILY",
		'GROUP': "GROUP",
	};

	//prettier-ignore
	const TripData = useMemo(() => {
	    const tripTypeMap = {
	        'ONE_WAY': "ONE WAY TRIP",
	        'TWO_WAY': "TWO WAY TRIP",
	        'ANNUAL_TRIP': "ANNUAL TRIP",
	    };

	    // Base info array with common fields
	    const baseInfo = [
	        { label: "Travel Direction", value: tripDetails?.travelDirection ?? "-" },
	        { label: "Destination", value: tripDetails?.destination ?? "-" },
	        { label: "Coverage Type", value: coverageMap[tripDetails?.coverageType] ?? "-" },
	        { label: "Number of Persons", value: tripDetails?.persons ?? "-" },
	    ];

	    // Date-related fields based on travel direction
	    const dateInfo = tripDetails?.travelDirection === COMMON.TRAVEL_DIRECTION.INBOUND
	        ? [
	            { label: "Arrival Date", value: formatDateWithHyphens(tripDetails?.arrival) ?? "-" },
	            { label: "Return Date", value: formatDateWithHyphens(tripDetails?.return) ?? "-" },
	        ]
	        : [
				{ label: "Trip Type", value: tripTypeMap[tripDetails?.tripType] ?? "-" },
	            { label: "Departure Date", value: formatDateWithHyphens(tripDetails?.depart) ?? "-" },
	            { label: "Return Date", value: formatDateWithHyphens(tripDetails?.return) ?? "-" },
	        ];

	    return {
	        title: "Trip Details",
	        info: [
	            ...baseInfo.slice(0, 1), // Travel Direction
	            ...dateInfo,             // Date fields based on direction
	            ...baseInfo.slice(1)     // Remaining fields
	        ]
	    };
	}, [tripDetails]);

	const ProviderId = useMemo(() => {

		let providerIdArray; 
		if(quotationInfo)
		{
			providerIdArray = Array.from(
				new Map(
					quotationInfo.map(item => [
						item.provider.providerName,
						{ label: item.provider.providerName, value: item.provider.providerName }
					])
				).values()
			);
		}

		return providerIdArray;
	}, []);
	
	return (
		<div className="page-quotation">
			<div className="quotation">
				<form className="quotation__form" onSubmit={formik.handleSubmit} onKeyPress={onHandleCheckEnterKey}>
					<p className="quotation__title">Quotation Details</p>
					<div className="quotation__container">
						<div className="quotation__wrapper">
							{/* prettier-ignore */}
							<AppCardInfo data={TripData} />
						</div>

						<div className="quotation__wrapper">
							{/* prettier-ignore */}
							
							<AppCardContainer title="Step 1" description="Select your preferred Insurer" subDescription="Insurers Found">
								<AppSearchInput ref={searchInputRef} multiValues={filterCriteria} onRemoveField={onHandleRemoveField} disabled={isLoading}>
									<form className="app-advance-search-form">
										<div className="advance-form">
											<div className="advance-form__header">
												<button type="button" className="advance-form__remove" onClick={onHandleCloseSearch}>
													<AppCloseIcon color="#666666" />
												</button>
											</div>

											<div className="advance-form__inputs">
												{/* <AppMultiSelectInput name="insurerList" label="Insurer" placeholder="e.g. AIA" options={COMMON.DROPDOWNS.SORT} value={formik.values.planId} /> */}
												<AppSelectInput name="sortProviderName" label="Provider" options={ProviderId} value={formik.values.sortProviderName} onChange={formik.handleChange} />
												<AppSelectInput name="sortAmount" label="Coverage Amount" options={COMMON.DROPDOWNS.SORT} value={formik.values.sortAmount} onChange={formik.handleChange} />
											</div> 

											<div className="advance-form__button-container">
												<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
												<AppButton type="button" label="Filter" onClick={onHandleSearch} />
											</div>
										</div>
									</form>
								</AppSearchInput>
							

								{!isLoading && !!quotationInfo?.length && <div className="card-insurer__wrapper">
									<QuotationCard onHandleChecked={onHandleChecked} insurerValue={formik.values.insurer} marketCoverage={formik.values.marketCoverage} />
								</div>}

								<div className="card-insurer__error-wrapper">
									<QuotationStatus insurerData={quotationCheckout} loadingCount={loading} isLoading={isLoading} formik={formik} noQuotationFound={!quotationInfo?.length} />
								</div>
								
							</AppCardContainer>

							{/* prettier-ignore */}
							{!isLoading && !!quotationCheckout?.length && (
								<Fragment>
									{/* prettier-ignore */}
									{/* <AppCardContainer title="Step 2" description="Select additional benefits" subDescription="Select All Benefits" onHandleSwitch={onHandleSwitch} isChecked={formik?.values?.isAllBenefit} disabled={formik.isSubmitting}>
										<div className="card-benefits__wrapper">
											<AdditionalBenefits formik={formik} onHandleCheckbox={onHandleCheckbox} onHandleValue={onHandleValue} />
										</div>
									</AppCardContainer> */}

									

									{/* prettier-ignore */}
									<div className="quotation__info-text">
									Plan is underwritten by individual Providers and distributed by VKA Wealth Planners. VKA Wealth Planners is an approved Financial Planning Firm under Bank Negara Malaysia. VKA Wealth Planners includes after sales and claim services.
									</div>

									{/* prettier-ignore */}
									<div className="quotation__info-text">
									Providers listed is a member of PIDM. The benefit(s) payable under eligible product is protected by PIDM up to limits. Please refer to <a className="quotation__info-link" href="https://www.pidm.gov.my/pidm2022/files/92/92bdfcde-3534-4a29-9031-5186387623ee.pdf" target="_blank" rel="noreferrer">PIDM's TIPS Brochure</a> or contact providers or <a className="quotation__info-link" href="https://www.pidm.gov.my" target="_blank" rel="noreferrer">PIDM</a>.
									</div>

									<div className="card-total-quotation">
										<div className="total-quotation">
											<div className="total-quotation__wrapper">
												<p className="total-quotation__title">Total Quotation</p>
											</div>

											<div className="total-quotation__wrapper">
												<p className="total-quotation__price">{`RM${formatCurrency(getHighestPremiumAmountForPlan(formik.values.planId))}`}</p>
												<p className="total-quotation__description">Premium From</p>
											</div>
										</div>
									</div>
								</Fragment>
							)}

							<div className="quotation__button-container">
								<AppButton outline type="button" label="Back" disabled={isLoading} onClick={onHandleNavigateEnquiry} />
								<AppButton type="submit" label="Next" disabled={isLoading} />
							</div>
						</div>
					</div>
				</form>
			</div>

			{/* prettier-ignore */}
			<AppRegenerateModal ref={regenerateModalRef} onHandleSubmit={onHandleSubmit} />
		</div>
	);
};

export default PageQuotation;
