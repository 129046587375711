import React, { useMemo, useCallback, useEffect, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { setReceipt } from "store/slices/travel";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppButton from "components/app-button";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import step1Icon from "assets/images/pages/page-receipt/step1-icon.webp";
import step2Icon from "assets/images/pages/page-receipt/step2-icon.webp";
import step3Icon from "assets/images/pages/page-receipt/step3-icon.webp";
import step4Icon from "assets/images/pages/page-receipt/step4-icon.webp";
import paymentSuccessIcon from "assets/images/pages/page-receipt/payment-success-icon.webp";
import appleAppStoreIcon from "assets/images/pages/page-app-store/apple-app-store-icon.svg";
import googleAppStoreIcon from "assets/images/pages/page-app-store/google-app-store-icon.svg";
import huaweiAppStoreIcon from "assets/images/pages/page-app-store/huawei-app-store-icon.svg";

const PageReceipt = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const [messageType, setMessageType] = useState("");
	const travel = useSelector((state) => state.travel);
	const receiptDetails = useMemo(() => travel?.receipt, [travel?.receipt]);

	//Placeholder
	const quotationInfoList = useMemo(() => travel?.quotationList, [travel?.quotationList]);
	const tripDetails = useMemo(() => travel?.travelDetails, [travel?.travelDetails]);
	const applicantInfo = useMemo(() => travel?.applicant, [travel?.applicant]);

	const onHandleNavigateHome = () => {
		navigate(pathnames.home);
	};

	const onHandleNavigateDownload = useCallback(() => {
		navigate(pathnames.appStore);
	}, [navigate]);

	const onHandleNavigateAppIos = () => {
		window.open(process.env.REACT_APP_APP_STORE);
	};

	const onHandleNavigateAppAndroid = () => {
		window.open(process.env.REACT_APP_PLAY_STORE);
	};

	const onHandleNavigateAppHuawei = () => {
		window.open(process.env.REACT_APP_GALLERY);
	};

	const formatDateWithHyphens = (date) => {
		return date ? new Date(date).toLocaleDateString('en-GB').replace(/\//g, '-') : "-";
	};

	const isPassport = (idType) =>{
		return idType === COMMON.IDENTIFICATION_TYPE.PASSPORT;
	}

	
	const getFullAddress = useMemo(() =>{
		return applicantInfo?.applicantInformation.address1 + 
		(applicantInfo?.applicantInformation.address2 && ", " + applicantInfo?.applicantInformation.address2) + 
		(applicantInfo?.applicantInformation.address3 && ", " + applicantInfo?.applicantInformation.address3)
		
	}, [])


	//const quotationDetail = quotationInfoList[0];

	 const getBenefitList = useMemo(() => {
	 	const benefitList = quotationInfoList?.flatMap((quotation) => quotation.ageGroup.benefits.
	 		map((benefit) => ({
	 			benefitName: benefit.benefitName,
	 			benefitAmount: benefit.coverageAmount,
	 			ageGroup: quotation.ageGroup.ageGroupName
	 		}))
	 	)
	 	console.log(benefitList)
	 	return benefitList;
	 }, [quotationInfoList]);

	 const getBenefitNameList = useMemo(() => {  
	 	const benefitList = quotationInfoList?.flatMap((quotation) =>   
	 		quotation.ageGroup.benefits.map((benefit) => benefit.benefitName)  
	 	  );  
	  
	 	const uniqueBenefitList = [...new Set(benefitList)];  
	  
	 	return uniqueBenefitList;  
	   }, [quotationInfoList]);

	   const getBenefitAmountByAgeGroupAndName = (ageGroup, benefitName) => {
	 	const benefit = getBenefitList.find(
	 	  (item) => item.ageGroup === ageGroup && item.benefitName === benefitName
	 	);
	 	return benefit ? benefit.benefitAmount : "N/A";
	   };

	 const BenefitList = useCallback(() => {  
	 	return (  
	 	  <ul className="quotation-list">  
	 		{getBenefitNameList?.map((o, index) => (  
	 		  <li className="quotation-list__item" key={index}>  
	 			<p className={className({ bold: false })}>{o}</p>  
	 			<p className={valueClassName({ bold: false, alignLeft: true })}>{getBenefitAmountByAgeGroupAndName("Children", o)}</p>  
	 			<p className={valueClassName({ bold: false, alignLeft: true })}>{getBenefitAmountByAgeGroupAndName("Adult", o)}</p>  
	 			<p className={valueClassName({ bold: false, alignLeft: true })}>{getBenefitAmountByAgeGroupAndName("Senior", o)}</p>  
	 		  </li>  
	 		))}  
	 	  </ul>  
	 	);  
	   }, [getBenefitNameList]);  



	{/*prettier-ignore*/}
	 const totalReceiptData = useMemo(() => ({
         order:[
             { label: "Reference Code", value: receiptDetails?.Status.referenceCode, bold: true },
             { label: "Payment Date", value: formatDatePattern(receiptDetails?.Status.paymentDate ? new Date(receiptDetails?.Status.paymentDate) : ""), bold: true },
			 { label: "Referral Code", value: receiptDetails?.TripDetails.referralCode, bold: true },
         ],
	 	tripDetails:[
	 		{ label: "Trip Details", value:"", bold: true },
	 		{ label: "Travel Direction", value: receiptDetails?.TripDetails.travelDirection },
	 		{ label: "Trip Type", value: receiptDetails?.TripDetails.tripType },
	 		{ label: "Arrival Date", value: formatDateWithHyphens(receiptDetails?.TripDetails.arrival) },
	 		{ label: "Return Date", value: formatDateWithHyphens(receiptDetails?.TripDetails.return) },
	 		{ label: "Destination", value: receiptDetails?.TripDetails.destination},
	 		{ label: "Coverage Type", value: receiptDetails?.TripDetails.coverageType },
	 		{ label: "Number of Persons", value: receiptDetails?.TripDetails.persons },
	 	],
	 	planDetails:[
	 		{ label: "Plan Details", value:"", bold: true },
	        //{ label: "Provider Name", value: quotationDetail?.provider.providerName ?? "-" },
	        //{ label: "Plan Name", value: quotationDetail?.plan.planName ?? "-" },
	        //{ label: "Plan Description", value: quotationDetail?.plan.description ?? "-" },
	 		{ label: "Coverage Amount", bold: true},
	 	],
	 	/*
	 	applicantDetails:[
	 		{ label: "Applicant Details / Certificate Owner", value:"", bold: true },
	 		{ label: "Name", value:applicantInfo?.applicantInformation.name },
	 		{ label: "Email", value:applicantInfo?.applicantInformation.email },
	 		{ label: "Identification Type", value:applicantInfo?.applicantInformation.identificationType },
	 		{ label: "Identification Number", value:applicantInfo?.applicantInformation.nric, hidden: isPassport(applicantInfo?.applicantInformation.identificationType) },
	 		{ label: "Passport", value:applicantInfo?.applicantInformation.passport, hidden: !isPassport(applicantInfo?.applicantInformation.identificationType) },
	 		{ label: "Passport Issued Date", value: formatDateWithHyphens(applicantInfo?.applicantInformation.passportIssuedDate), hidden: !isPassport(applicantInfo?.applicantInformation.identificationType)},
	 		{ label: "Passport Expired Date", value: formatDateWithHyphens(applicantInfo?.applicantInformation.passportExpiredDate), hidden: !isPassport(applicantInfo?.applicantInformation.identificationType)},
	 		{ label: "Age", value: applicantInfo?.applicantInformation.age + " years old"},
	 		{ label: "Birth Date", value:formatDateWithHyphens(applicantInfo?.applicantInformation.birthDate) },
	 		{ label: "Gender", value: applicantInfo?.applicantInformation.gender },
	 		{ label: "Address", value: getFullAddress},
	 		{ label: "City", value:applicantInfo?.applicantInformation.city },
	 		{ label: "State", value:applicantInfo?.applicantInformation.state },
	 		{ label: "Postcode", value:applicantInfo?.applicantInformation.postcode },
	 		{ label: "Mobile", value:applicantInfo?.applicantInformation.mobileNoFull },
	 		{ label: "Nationality", value:applicantInfo?.applicantInformation.nationality },
	 	],
	 	*/
         insurancePremium:[
	 		{ label: "Insurance Premium", value: "", bold: true },
	 		{ label: "Gross Premium", value: `RM${formatCurrency(receiptDetails?.grossPremium)}`, bold: true },
	 		{ label: "SST (8%)", value: `RM${formatCurrency(receiptDetails?.sst)}` },
	 		{ label: "Stamp Duty Fee", value: `RM${formatCurrency(receiptDetails?.stampDutyFee)}` },
         ],
         totalPayable: [
             { label: "Total Payable:", value: `RM${formatCurrency(receiptDetails?.totalPayable)}`, bold: true }
	 	]
	 }), [receiptDetails]);

	 const className = useCallback((obj) => {
	 	return classNames({
	 		"quotation-list__text": true,
	 		"quotation-list__description": obj?.bold,
	 	});
	 }, []);

	 const valueClassName = useCallback((obj) => {
	 	return classNames({
	 		"quotation-list__text": !obj?.bold,
	 		"quotation-list__text--value": !obj?.bold,
	 		"quotation-list__text--value--alignLeft": obj?.alignLeft,
	 		"quotation-list__description": obj?.bold,
	 		"quotation-list__description--value": obj?.bold,
	 	});
	 }, []);

	 const headerClassName = useCallback((obj) => {
	 	return classNames({
	 		"quotation-list__description": true,
	 		"quotation-list__description--value": obj?.bold,
	 	});
	 }, []);

	 const onHandleCheckPaymentStatus = useCallback(async () => {
	 	let response = null;

	 	const queryString = window.location.search;
	 	const urlParams = new URLSearchParams(queryString);
	 	const paymentId = urlParams.get("paymentId");

	 	try {
	 		let payload = {
	 			paymentId: paymentId,
	 		};

	 		response = await api.get.travel.paymentStatus(payload);

	 		if (response.status.status === COMMON.MESSAGE_TYPE.PAID) {
	 			let data = {
					TripDetails: {
						travelDirection: response.tripDetail.travelDirection,
						tripType: response.tripDetail.tripType,
						arrival: response.tripDetail.arrival,
						depart: response.tripDetail.depart,
						return: response.tripDetail.return,
						coverageType: response.tripDetail.coverageType,
						destination: response.tripDetail.destination,
						persons: response.tripDetail.persons,
						referralCode: response.tripDetail.referralCode,
					},
					Status: {
						referenceCode: response.status.vendor_payment_id,
						paymentDate: response.status.payment_date,
					}
	 			};

	 			dispatch(setReceipt(data));
	 		} else {
	 			setMessageType(COMMON.MESSAGE_TYPE.PAYMENT_FAILED);
	 		}
	 	} catch (error) {
	 		switch (error?.response?.data?.errorCode) {
	 			case COMMON.ERRORS.PAYMENT_QUOTATION_INVALID:
	 			case COMMON.ERRORS.PAYMENT_EXIST:
	 				setMessageType(COMMON.MESSAGE_TYPE.PAYMENT_FAILED);
	 				break;
	 			default:
	 				serveRequestErrors(error);
	 				break;
	 		}
	 	}
	 }, [dispatch]);

	useEffect(() => {
		onHandleCheckPaymentStatus();
	}, [onHandleCheckPaymentStatus]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.INSURER.PAYMENT_STATUS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-receipt">
			<div className="receipt">
				{!messageType && (
					<Fragment>
						<div className="receipt__body">
							<img className="receipt__icon" src={paymentSuccessIcon} alt="payment success" />
							<p className="receipt__status">Payment Successful!</p>

							<div className="receipt__container">
								<div className="receipt__wrapper">
									<p className="receipt__title">Payment Summary</p>
								</div>

								<div className="receipt__wrapper">
									<div className="receipt-form__container">
										{ <div className="receipt-form__header">
											{totalReceiptData.order.map((o) => {
												return (
													<ul className="header-quotation-list" key={o.label}>
														<li className="header-quotation-list__item">
															<p className={className()}>{o.label}</p>
															<p className={headerClassName({ bold: o.bold, value: true })}>{o.value}</p>
														</li>
													</ul>
												);
											})}
										</div>}

										<div className="receipt-form__divider" />

										<div className="receipt-form__body">
											{ <div className="receipt-form__wrapper">
												{totalReceiptData.tripDetails.map((o) => {
													if (!o.hidden) {
													return (
														<ul className="quotation-list" key={o.label}>
														<li className="quotation-list__item">
															<p className={className({ bold: o.bold })}>{o.label}</p>
															<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
														</li>
														</ul>
													);
													}
													return null;
												})}
											</div>}

											{ <div className="receipt-form__wrapper">
												{totalReceiptData.planDetails.map((o) => {
													if (!o.hidden) {
													return (
														o.label === "Coverage Amount" ?
															<>
																<ul className="quotation-list" key="coverage">
																	<li className="quotation-list__item">
																		<p className={className({ bold: o.bold })}>{o.label}</p>
																	</li>
																</ul>		

																<ul className="quotation-list" key={o.label}>
																	<li className="quotation-list__item">
																		<p className={className({ bold: false })}>Benefits Details</p>
																		<p className={className({ bold: false })}>Children <br/>(0-17 y/0).</p>
																		<p className={className({ bold: false })}>Adult <br/>(18 - 69 y/o).</p>
																		<p className={className({ bold: false })}> Senior <br/>(70 - 85 y/o)</p>
																	</li>
																</ul>

																<BenefitList />
															</>
															:
															<ul className="quotation-list" key={o.label}>
																<li className="quotation-list__item">
																	<p className={className({ bold: o.bold })}>{o.label}</p>
																	
																	<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
																</li>
															</ul>														
													);
													}
													return null;
												})}
											</div> }

											{ <div className="receipt-form__wrapper">
												{totalReceiptData.applicantDetails?.map((o) => {
													if (!o.hidden) {
													return (
														<ul className="quotation-list" key={o.label}>
														<li className="quotation-list__item">
															<p className={className({ bold: o.bold })}>{o.label}</p>
															<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
														</li>
														</ul>
													);
													}
													return null;
												})}
											</div>}
											{/*

											{applicantInfo?.coveredMembers.map((member, index) => {
													return (
														<div className="receipt-form__wrapper">
															<li className="quotation-list__item">
																<p className={className({ bold: true})}>Insured Member {index + 1}</p>
															</li>	
															<ul className="quotation-list" key={member.name}>
																<li className="quotation-list__item">
																	<p className={className()}>Name</p>
																	<p className={valueClassName({ bold: false})}>{member.name}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Email</p>
																	<p className={valueClassName({ bold: false })}>{member.email}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Identification Type</p>
																	<p className={valueClassName({ bold: false })}>{member.identificationType}</p>
																</li>
																{!isPassport(member.identificationType) ?
																	<li className="quotation-list__item">
																		<p className={className()}>Identification Number</p>
																		<p className={valueClassName({ bold: false })}>{member.nric}</p>
																	</li>
																	:
																	<>
																		<li className="quotation-list__item">
																			<p className={className()}>Passport</p>
																			<p className={valueClassName({ bold: false })}>{member.passport}</p>
																		</li>
																		<li className="quotation-list__item">
																			<p className={className()}>Passport Issued Date</p>
																			<p className={valueClassName({ bold: false })}>{formatDateWithHyphens(member.passportIssuedDate)}</p>
																		</li>
																		<li className="quotation-list__item">
																			<p className={className()}>Passport Expired Date</p>
																			<p className={valueClassName({ bold: false })}>{formatDateWithHyphens(member.passportExpiredDate)}</p>
																		</li>
																	</>
																}
																<li className="quotation-list__item">
																	<p className={className()}>Age</p>
																	<p className={valueClassName({ bold: false })}>{member.age + " years old"}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Birth Date</p>
																	<p className={valueClassName({ bold: false })}>{formatDateWithHyphens(member.birthDate)}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Gender</p>
																	<p className={valueClassName({ bold: false })}>{member.gender}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Mobile</p>
																	<p className={valueClassName({ bold: false })}>{member.mobileNoFull}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Nationality</p>
																	<p className={valueClassName({ bold: false })}>{member.nationality}</p>
																</li>
															</ul>
														</div>

												);
											})}
*/}
											{/* <div className="receipt-form__wrapper">
												{totalReceiptData.insurancePremium.map((o) => {
													return (
														<ul className="quotation-list" key={o.label}>
															<li className="quotation-list__item">
																<p className={className({ bold: o.bold })}>{o.label}</p>
																<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
															</li>
														</ul>
													);
												})}
											</div>
										</div> */}

										{/* <div className="receipt-form__divider" />

										<div className="receipt-form__payable-amount">
											{totalReceiptData.totalPayable.map((o) => {
												return (
													<ul className="quotation-list" key={o.label}>
														<li className="quotation-list__item">
															<p className={className({ bold: o.bold })}>{o.label}</p>
															<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
														</li>
													</ul>
												);
											})}
										</div> */}
</div>
										<div className="receipt-form__divider" />

										<div className="receipt-form__footer">
											<p className="receipt-form__description">Thank you for insuring with easycover.</p>
											<p className="receipt-form__description">An covernote / policy document will be sent to your email within 24 hours. </p>
										</div>
									</div>
								</div>
							</div>

							<div className="step__body">
								<div className="step__wrapper">
									<p className="step__text">Download our mobile app to view policy details. To get started, please follow these simple steps:</p>
								</div>

								<div className="step__wrapper">
									<div className="step__box">
										<p className="step__title">Step 1</p>

										<div className="step__content">
											<img src={step1Icon} alt="step1" />
											<p className="step__text">Download ‘easycover.my’ app from App Store, Google Play, App Gallery or click the link to download the app.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 2</p>

										<div className="step__content">
											<img src={step2Icon} alt="step2" />
											<p className="step__text">Login in or create an account using the same email address that purchase the insurance plan.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 3</p>

										<div className="step__content">
											<img src={step3Icon} alt="step3" />
											<p className="step__text">Tap on the My Coverage.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 4</p>

										<div className="step__content">
											<img src={step4Icon} alt="step4" />
											<p className="step__text">Tap to view policy details.</p>
										</div>
									</div>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 1: Download the Mobile App</p>

									<div className="step__button-container">
										<button type="button" className="step__button" onClick={onHandleNavigateAppIos}>
											<img src={appleAppStoreIcon} alt="apple" />
										</button>

										<button type="button" className="step__button" onClick={onHandleNavigateAppAndroid}>
											<img src={googleAppStoreIcon} alt="google" />
										</button>

										<button type="button" className="step__button" onClick={onHandleNavigateAppHuawei}>
											<img src={huaweiAppStoreIcon} alt="huawei" />
										</button>
									</div>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 2: Create your account</p>
									<p className="step__sub-text">Once the app is installed, open it and tap on “Login/Sign up” and tap on “Sign up” on pop up screen.</p>

									<ul className="step-list">
										<li className="step-list__item">Please sign up with your personal email address and relevant details.</li>
										<li className="step-list__item">Complete the verification process to confirm your account.</li>
									</ul>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 3: Explore Your Benefits</p>
									<p className="step__sub-text">
										Congratulations! You are now set up to enjoy the full range of company benefits, including insurance coverage. Inside the app, you can easily view your insurance policy details, coverage limits, claim
										procedures, and more.
									</p>
								</div>
							</div>
						</div>

						<div className="receipt__button-container">
							<AppButton outline type="button" label="Download app" onClick={onHandleNavigateDownload} />
							<AppButton type="button" label="Back to home" onClick={onHandleNavigateHome} />
						</div>
					</Fragment>
				)}
				{messageType && (
					<div className="receipt__body">
						<AppCardStatus messageType={messageType} />
					</div>
				)}
			</div>
		</div>
	);
};

export default PageReceipt;
