const pathnames = {
	home: "/",
	appStore: "/travel/appStore",
	tripType: "/travel/tripType",
	quotation: "/travel/quotation",
	payment: "/travel/payment",
	applicant: "/travel/applicant",
	summary: "/travel/summary",
	receipt: "/travel/receipt",
	bulk: "/travel/bulk",
	
};

export default pathnames;
