import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	enquiry: null,
	quotation: null,
	applicant: null,
	receipt: null,
	referralCode: null
};

const travelSlice = createSlice({
	name: "travel",
	initialState,
	reducers: {
		setQuotationList(state, action) {
			return Object.assign(state, { quotationList: action.payload });
		},
		setQuotation(state, action) {
			return Object.assign(state, { quotation: action.payload });
		},
		setApplicant(state, action) {
			return Object.assign(state, { applicant: action.payload });
		},
		setReferralCode(state, action) {
			return Object.assign(state, { referralCode: action.payload });
		},
		setReceipt(state, action) {
			return Object.assign(state, { receipt: action.payload });
		},
		setTravelDetail(state, action) {
			return Object.assign(state, { travelDetails: action.payload });
		},
		setSummaryDetail(state, action) {
			return Object.assign(state, { summaryDetails: action.payload });
		},
		setTempFiles: (state, action) => { // Add this reducer
			state.tempFiles = action.payload;
		},
		clearTempFiles: (state) => { // Add this reducer
			state.tempFiles = [];
		},
		resetTravel() {
			return initialState;
		},
	},
});

export const { setQuotationList, setPremiumList, setQuotation, setApplicant, setReceipt, setReferralCode, resetTravel, setTravelDetail, setSummaryDetail, setTempFiles, clearTempFiles } = travelSlice.actions;

export default travelSlice.reducer;
