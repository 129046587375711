import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getNationalityListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { lookupID: "COUNTRY"};
		const res = await api.get.general.list(params);
		const content = res.map(({ nationality, ...o }) => ({ label: nationality, value: nationality, ...o }));
		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getNationalityListing;
