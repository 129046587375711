import React, { memo } from "react";

const AppInfoIcon = ({ className, color = "blue", ...props }) => {
	return (
		<svg {...props} className={className || "infor-icon"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
            <circle cx="12" cy="12" r="10" fill={color} />
            <text x="12" y="18" fontSize="12" fill="white" textAnchor="middle" fontFamily="Arial" fontWeight="bold">i</text>
        </svg>
	);
};

export default memo(AppInfoIcon);
